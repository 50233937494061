import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ENV_CONFIG } from '../shared/environment-config.interface';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';

export interface IAuthResponse {
  Result: boolean,
  Error: any,
  Other: any
}

export interface IAuthParams {
  token: string;
  codicefiscale: string;
  iframe?: number;
  iframenewtab?: number;
  ruolo?: string;
  pagina?: number;
}

@Injectable({
  providedIn: 'root'
})
export class LoginCondominiService {

  private AUTH_CONDOMINI = "login/authCondomini";
  private GET_TOKEN = "login/getCondominioToken";
  public token = "";

  constructor(
    private http: HttpClient,
    private api: ApiService,

  ) {
    console.log("########### e n v i r o n m e n t  ", environment.production);
    console.log("Altri per test: 0123456789123456 => ", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RpY2VGaXNjYWxlIjoiMDEyMzQ1Njc4OTEyMzQ1NiIsImlhdCI6MTcyMzEwNjY3NSwiZXhwIjoxNzUwMTkwNjY3NX0.PJO8JB2hseDYtLdWFJi1yP399_vK1eX3lROrcYsOmCY");
    /** condominio San rocco 3 COND20240000025 */
    if (!environment.production) {
      this.token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RpY2VGaXNjYWxlIjoiQ09ORDIwMjQwMDAwMDI1IiwiaWF0IjoxNzIzMTA2NTMyLCJleHAiOjE3NTAxOTA2NTMyfQ.Lz28wOMlHfpUaHYgxgCfvFT4Y71_VUiDbhZKsuA2ST8";
      console.log("Selezionato Condominio San Rocco 3 ", this.token);
    }

    /** condominio Larici */
    // if (!environment.production) {
    //   this.token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RpY2VGaXNjYWxlIjoiMDAwMDAwMDAwMDAwMDAwMSIsImlhdCI6MTcyNTUyNzY4MywiZXhwIjoxNzUwNDMyNzY4M30.jYW-agaEbQ9Ysfs00vnKPkRpeO5zC8C8CICmrrkBkZk";
    //   console.log("Selezionato Condominio Larici ", this.token);
    //   console.log("Altri per test: 0123456789123456 => ", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RpY2VGaXNjYWxlIjoiMDEyMzQ1Njc4OTEyMzQ1NiIsImlhdCI6MTcyMzEwNjY3NSwiZXhwIjoxNzUwMTkwNjY3NX0.PJO8JB2hseDYtLdWFJi1yP399_vK1eX3lROrcYsOmCY");
    // }

    /** condominio Salici */
    // if (!environment.production) {
    //   this.token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RpY2VGaXNjYWxlIjoiQ09ORDIwMjQwMDAwMDEzIiwiaWF0IjoxNzI1OTU1NzQwLCJleHAiOjE3NTA0NzU1NzQwfQ.8rksjxkuiQqoaLVo8moo_whFAbYnn86IzWMUU9yLzG0";
    //   console.log("Selezionato Condominio Quercie ", this.token);
    // }

    /** condominio Quercie ALPINISTI*/
    // if (!environment.production) {
    //   this.token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RpY2VGaXNjYWxlIjoiMDAwMDAwMDAwMDAwMDAwMiIsImlhdCI6MTcyNTk1NDY5MCwiZXhwIjoxNzUwNDc1NDY5MH0.kshOVbDx-RbY9_fRkBoLsHT26X0mnRzxGSpNJWO1oVA";
    //   console.log("Selezionato Condominio Quercie ", this.token);
    // }

    /** Test COND20240000036*/
    // if (!environment.production) {
    //   this.token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RpY2VGaXNjYWxlIjoiQ09ORDIwMjQwMDAwMDM2IiwiaWF0IjoxNzI4OTc4MzMyLCJleHAiOjE3NTA3Nzc4MzMyfQ.TIuu12bCB_x8DpFjz6Dqhvv2ZQlYjdwLue6_ju7hCZc";
    //   console.log("Selezionato Condominio COND20240000036 ", this.token);
    // }

    /** test del 23 ottobre */
    // if (!environment.production) {
    //   this.token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RpY2VGaXNjYWxlIjoiQ09ORDIwMjQwMDAwMDM5IiwiaWF0IjoxNzI5NjgwMzI3LCJleHAiOjE3NTA4NDgwMzI3fQ.FI6fuCq7SCyRqP4Ni63GylJgPBpt - ZVA_j9 - 3l6b1tg";
    //   console.log("Test del 23 ottobre \nSelezionato Condominio COND20240000039 ", this.token);
    // }
    /** creato il 28/10 da enrico */
    // if (!environment.production) {
    //   this.token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RpY2VGaXNjYWxlIjoiQ09ORDIwMjQwMDAwMDQwIiwiaWF0IjoxNzMwMTkwOTg2LCJleHAiOjE3NTA4OTkwOTg2fQ.0GWEnbTS3jOHzw6xnPFfGyUKby1137HJg7LVRGpLCEI";
    //   console.log("creato il 28/10 da enrico \nSelezionato Condominio COND20240000040 ", this.token);
    // }
  }

  async verificaAccesso(params: IAuthParams): Promise<boolean> {
    return new Promise((res, rej) => {
      this.http.post(this.api.apiExpress + this.AUTH_CONDOMINI, params).subscribe((a: IAuthResponse) => {
        res(a.Result),
          console.log("Autorizzato ", a.Result["Result"]);

      }, error => {
        rej(error);
        console.error("Errore nell'autenticazione servizio condominio", error);
      })
    })
  }

  async getCondominioToken(codiceFiscale: string): Promise<string> {
    return new Promise((res, rej) => {
      this.http.post(this.api.apiExpress + this.GET_TOKEN, {
        codiceFiscale: codiceFiscale
      }).subscribe((r: any) => {
        this.token = r.Result.token;
        // console.log("token caricato: ", this.token);
        res(this.token)
      }, error => {
        rej(error);
        console.error("Errore nel recupero del token ", error);
      })
    })
  }

}
